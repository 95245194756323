function throw_if(condition, exception) {
    if (condition) {
        throw exception;
    }
}

function throw_unless(condition, exception) {
    if (!condition) {
        throw exception;
    }
}
